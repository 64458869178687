

exports.clientModuleList = {
    dashboard: {
        title: "dashboard",
        pathnames: ["/client/dashboard", "/client/dashboard/", "/client", "/client/"]
    },
    history: {
        title: "history",
        pathnames: ["/client/history", "/client/history/"]
    },
    guard: {
        title: "guards",
        pathnames: ["/client/patrol-guard", "/client/patrol-guard/"]
    },
    beats: {
        title: "beats",
        pathnames: ["/client/beats", "/client/beats/"]
    },
    settings: {
        title: "settings",
        pathnames: ["/client/settings", "/client/settings/"]
    }
}

import React from 'react';

const ActivateGuard = () => {
    return (
        <>
            activate-guard-app works!
        </>
    );
}

export default ActivateGuard;

import { Link, useLocation, useParams } from "react-router-dom";

function FaqsToolbar() {
  const location = useLocation();
  const { beatId } = useParams();
  /**URLS */

  return <></>;
}

export default FaqsToolbar;

import React from 'react';
import { Link } from 'react-router-dom';

const PaymentFailure = () => {
    return (
        <>
            {/* payment-failure-app works! */}


            <div className="mx-auto max-w-[500px] my-16">
                <div className="min-h-96 flex place-content-center flex-col">


                    <div className="cancel-icon | hide relative inline-flex mx-auto mb-12">
                        {/* <!-- cancel circle --> */}
                        <div>
                            <svg width="110" height="109" viewBox="0 0 110 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M54.6698 0C24.3556 0 0 24.5595 0 54.4375C0 84.3154 24.3556 108.875 54.6698 108.875C84.984 108.875 109.301 84.3154 109.301 54.4375C109.301 24.5595 84.984 0 54.6698 0ZM54.6698 4.76231C81.8341 4.76231 104.151 26.8369 104.151 54.4375C104.151 82.038 81.8341 104.113 54.6698 104.113C27.5055 104.113 5.26565 82.038 5.26565 54.4375C5.26565 26.8369 27.5055 4.76231 54.6698 4.76231ZM39.8795 37.1306C39.3843 37.1268 38.8993 37.2714 38.4871 37.5459C38.0749 37.8204 37.7544 38.212 37.5669 38.6704C37.3794 39.1287 37.3335 39.6327 37.4352 40.1174C37.5368 40.6021 37.7814 41.0452 38.1372 41.3895L51.1852 54.4375L38.1372 67.4468C37.901 67.6765 37.7129 67.951 37.584 68.2543C37.4551 68.5575 37.388 68.8835 37.3866 69.213C37.3852 69.5425 37.4495 69.869 37.5758 70.1734C37.7021 70.4777 37.8879 70.7538 38.1221 70.9856C38.3564 71.2173 38.6346 71.3999 38.9403 71.5229C39.2461 71.6458 39.5732 71.7065 39.9027 71.7015C40.2322 71.6964 40.5574 71.6257 40.8592 71.4935C41.161 71.3612 41.4335 71.1702 41.6606 70.9314L54.6698 57.9221L67.6791 70.9314C67.9061 71.1702 68.1786 71.3612 68.4804 71.4935C68.7822 71.6257 69.1074 71.6964 69.4369 71.7015C69.7664 71.7065 70.0935 71.6458 70.3993 71.5229C70.705 71.3999 70.9832 71.2173 71.2175 70.9856C71.4518 70.7538 71.6375 70.4777 71.7638 70.1734C71.8901 69.869 71.9544 69.5425 71.953 69.213C71.9516 68.8835 71.8845 68.5575 71.7556 68.2543C71.6267 67.951 71.4386 67.6765 71.2024 67.4468L58.1544 54.4375L71.2024 41.3895C71.5637 41.0407 71.8104 40.5903 71.91 40.0981C72.0095 39.6058 71.9572 39.0949 71.7598 38.6331C71.5625 38.1713 71.2295 37.7803 70.805 37.512C70.3805 37.2436 69.8845 37.1106 69.3826 37.1306C68.7354 37.1561 68.1239 37.4341 67.6791 37.9049L54.6698 50.9142L41.6606 37.9049C41.4311 37.6626 41.1551 37.4691 40.8491 37.336C40.543 37.2029 40.2132 37.1331 39.8795 37.1306Z"
                                    fill="#FA5252" />
                            </svg>

                        </div>
                    </div>

                    <p className="text-lg font-bold text-center text-[#FA5252]">Oops!</p>
                    <div className="my-4"></div>
                    <Link to="/onboarding/services/" className='rounded-md text-white focus:ring-1 focus:outline-none focus:ring-green-300
      font-medium text-center dark:bg-green-600 dark:hover:bg-green-700
      dark:focus:ring-green-800 bg-primary-500 px-5 py-2.5 sm:py-3'>Try again</Link>
                </div>
            </div>
        </>
    );
}

export default PaymentFailure;

import React from "react";
import Checkout from "../../../Onboarding/Subscription/Checkout";

const CheckoutSubscription = () => {
  return (
    <>
      {/* checkout-subscription-app works! */}
      <div className="border shadow-md rounded-lg p-4">
        <Checkout marginY={`my-0`} />
      </div>
    </>
  );
};

export default CheckoutSubscription;

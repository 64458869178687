import React, { useContext, useEffect, useState } from "react";
import TextInputField from "../../Sandbox/InputField/TextInputField";
import SelectField from "../../Sandbox/SelectField/SelectField";
import RegularButton from "../../Sandbox/Buttons/RegularButton";
import { useParams } from "react-router-dom";

import useHttpRequest from "../../../shared/Hooks/HttpRequestHook";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  selectOrganization,
  selectToken,
  selectUser,
} from "../../../redux/selectors/auth";
import * as Yup from "yup";
import { useFormik } from "formik";

import imageCompression from "browser-image-compression";
import { patch } from "../../../lib/methods";
import { FileInput } from "flowbite-react";
import { useGetGuardsQuery } from "../../../redux/services/guards";
import { FaFileAlt } from "react-icons/fa";
import { ASSET_URL } from "../../../constants/api";
import { COMPRESSION_OPRIONS } from "../../../constants/statics";

const identificationTypeOptions = [
  {
    name: "National Identification Number (NIN)",
    value: "NIN",
  },
  {
    name: "Drivers License",
    value: "Drivers License",
  },
  {
    name: "International Passport",
    value: "International Passport",
  },

  {
    name: "Voter's Card",
    value: "Voter's Card",
  },
];

const validationSchema = Yup.object({
  idnumber: Yup.string().required("identification number is required"),
  idname: Yup.string().required("identification file type is required"),
  guardIdentificationFile: Yup.mixed().required(
    "Guard Identification File is required"
  ),
});

const EditIdentification = (props) => {
  const { guardId } = useParams();
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const [updateloading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const organization = useSelector(selectOrganization);

  const {
    data: guards,
    refetch: refetchGuards,
    isUninitialized,
  } = useGetGuardsQuery(
    { organization },
    {
      skip: organization ? false : true,
    }
  );

  const { isLoading, error, responseData, sendRequest } = useHttpRequest();
  const [validationErrors, setValidationErrors] = useState({});
  const [formData, setFormData] = useState({
    idnumber: props.guard?.idnumber || "",
    idname: props.guard?.idnameZZ || "",
    guardIdentificationFile: null || "",
  });

  useEffect(() => {
    setFormData({
      idnumber: props.guard?.idnumber || "",
      idname: props.guard?.idname || "",
      guardIdentificationFile: props.guard?.identificationsFile || "",
    });
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const [identificationType, setIdentificationType] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setValidationErrors({ ...validationErrors, [e.target.name]: "" });
  };

  const handleSelectChange = (e) => {
    let type = e.target;
    setFormData({ ...formData, [e.target.name]: type.value });
    setValidationErrors({ ...validationErrors, [e.target.name]: "" });
  };

  const handleFileChange = async (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      try {
        formik.values.guardIdentificationFile = uploadedFile;
      } catch (error) {
        console.error("Error during image compression:", error);
      }
    }
  };

  const save = async (e) => {
    setLoading(true);
    try {
      e.preventDefault();

      if (!formData.idname) {
        toast.warn("select a valid identification type");
        return;
      }

      const newFormData = new FormData();
      newFormData.append("idname", formData.idname);
      newFormData.append("idnumber", formData.idnumber);
      newFormData.append(
        "guardIdentificationFile",
        formData.guardIdentificationFile
      );

      const data = patch(
        `guard/identification/${guardId}`,

        newFormData,
        token
      ).then((data) => {
        if (data?.status) {
          toast("Identification Information Updated");
          //props.setGuard({})

          setFormData({
            idnumber: "",
            idname: "",
            guardIdentificationFile: "",
          });
          refetchGuards();
        }
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      idnumber: props.guard?.idnumber || "",
      idname: props.guard?.idname || "",
      guardIdentificationFile: props.guard?.identificationsFile || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        let compressedFile = null;
        if (typeof values?.guardIdentificationFile === "object") {
          compressedFile = await imageCompression(
            values.guardIdentificationFile,
            COMPRESSION_OPRIONS
          );
        }

        if (!values.idname) {
          toast.warn("select a valid identification type");
          return;
        }

        const newFormData = new FormData();
        newFormData.append("idname", values.idname);
        newFormData.append("idnumber", values.idnumber);
        newFormData.append("guardIdentificationFile", compressedFile);

        const data = await patch(
          `guard/identification/${guardId}`,

          newFormData,
          token
        ).then((data) => {
          if (data?.status) {
            toast("Identification Information Updated");
            //props.setGuard({})

            setFormData({
              idnumber: "",
              idname: "",
              guardIdentificationFile: "",
            });
            refetchGuards();
          }
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      {/* edit-identification-app works! */}

      <form onSubmit={formik.handleSubmit}>
        <div className="mx-auto max-w-xl">
          <fieldset>
            <legend className="text-xl font-semibold mb-8 text-center">
              Identification
            </legend>
            <div className="grid grid-cols-12 gap-x-4">
              <div className="col-span-12">
                <SelectField
                  defaultValue={formData?.idname}
                  label="Identification Type"
                  optionList={identificationTypeOptions}
                  multipleSelect={false}
                  error={formik.errors.idname}
                  {...formik.getFieldProps("idname")}
                />
              </div>
              <div className="col-span-12">
                <TextInputField
                  label="Identification Number"
                  type="text"
                  name="idnumber"
                  error={formik.errors.idnumber}
                  {...formik.getFieldProps("idnumber")}
                />
              </div>
            </div>
            <div className="col-span-12  mb-3">
              <label htmlFor="fileUpload" className="semibold_label">
                Upload Identification File
              </label>
              {props.guard?.identificationsFile && (
                <a
                  target="_blank"
                  href={`${ASSET_URL + props.guard?.identificationsFile}`}
                >
                  <div className="flex items-center mt-2 my-3">
                    <div>
                      <FaFileAlt className="mr-2" size={"3rem"} />
                    </div>
                    <div>
                      <span>
                        {props.guard?.idname || "Previously uploaded file"}
                      </span>{" "}
                      <br />
                      <span>
                        {props.guard?.idnumber || "Previously uploaded file"}
                      </span>
                    </div>
                  </div>
                </a>
              )}
              <div className=" col-span-6">
                <input
                  // {...formik.getFieldProps("identificationFile")}
                  type="file"
                  onChange={(e) => handleFileChange(e)}
                  className="file-input"
                />
                {formik.errors.guardIdentificationFile && (
                  <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                    <span className="font-medium">
                      {formik.errors.guardIdentificationFile}
                    </span>
                  </p>
                )}
              </div>
            </div>
            <RegularButton
              disabled={updateloading}
              isLoading={updateloading}
              text="Update"
            />
          </fieldset>
        </div>
      </form>
    </>
  );
};

export default EditIdentification;

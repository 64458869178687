export const DUTY_STATUS = {
  OFF_DUTY: 0,
  ON_DUTY: 1,
};

export const COMPRESSION_OPRIONS = {
  maxSizeMB: 0.5,
  maxWidthOrHeight: 800,
  useWebWorker: true,
};
